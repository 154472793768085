<template>
	<div>
		<div class="content">
			<div class="all">
				 
				<div class="SearchBar">
					<el-radio-group v-model="radio" size="mini"  style="margin-bottom:10px" @change="radioChange">
					<el-radio-button label="1">全部</el-radio-button>
					<el-radio-button label="2">未完成</el-radio-button>
				</el-radio-group>
					<el-form
						ref="search"
						:inline="true"
						:model="pageParams"
						size="small"
					>
						<el-form-item label="用户分类:" class="searchItem">
							<el-select
								v-model="pageParams.residentType"
								placeholder="请选择"
								size="mini"
								clearable
								style="width: 160px"
								
							>
							<!-- @change="loadList(pageParams,1)" -->
								<el-option
									v-for="(item, index) in userTypeList"
									:key="index"
									:label="item.name"
									:value="item.id"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="优先级:" class="searchItem">
							<el-select
								v-model="pageParams.priorLevel"
								placeholder="请选择"
								size="mini"
								clearable
								style="width: 160px"
								
							>
							<!-- @change="loadList(pageParams,1)" -->
								<el-option
									v-for="(item, index) in priorityList"
									:key="index"
									:label="item.priorLevel"
									:value="item.id"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="受理部门:" class="searchItem">
							<el-cascader
								v-model="pageParams.deptId"
								:options="sectionList"
								:props="TreeProps"
								size="mini"
								style="width: 160px"
								:show-all-levels="false"
								ref="cascaderHandle"
								@change="selectDepartment"
							>
								<span slot-scope="{ data }">
									{{ data.name }}
								</span>
							</el-cascader>
						</el-form-item>
						<el-form-item label="受理人:" class="searchItem">
							<el-input
								v-model="pageParams.userName"
								size="mini"
								placeholder="请输入关键字"
							></el-input>
						</el-form-item>
						<el-form-item label="维修员:" class="searchItem">
							<el-input
								v-model="pageParams.repairman"
								size="mini"
								placeholder="请输入关键字"
							></el-input>
						</el-form-item>

						<el-form-item label="工单状态:" class="searchItem">
							<el-select
								v-model="pageParams.status"
								placeholder="请选择"
								size="mini"
								multiple
								collapse-tags
								clearable
								style="width: 160px"
							>
								<el-option
									v-for="(item, index) in statusList"
									:key="index"
									:label="item.name"
									:value="item.id"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否超时:" class="searchItem">
							<el-select
								style="width: 160px"
								v-model="pageParams.isOvertime"
								placeholder="请选择"
								size="mini"
								clearable
								
							>
							<!-- @change="loadList(pageParams,1)" -->
								<el-option
									v-for="(item, index) in isOvertimeList"
									:key="index"
									:label="item.name"
									:value="item.id"
								></el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="时间段:" class="searchItem">
							<el-date-picker
								size="mini"
								clearable
								style="width: 300px"
								v-model="time"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd"
								@change="timeChange"
							>
							</el-date-picker>
						</el-form-item> -->
						<el-select
							v-model="timeStart"
							style="width: 100px; margin: 2px 0px 2px 10px;"
							placeholder="请选择"
							size="mini"
							class="choose special"
							@change="chooseTypeTime"
						>
							<el-option
								v-for="item in timeList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
						<el-date-picker
								size="mini"
								clearable
								style="width: 225px;border-radius: 0 4px 4px 0;"
								v-model="time"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd"
								@change="timeChange"
							>
							</el-date-picker>
						<el-select
							v-model="chooseType"
							style="width: 100px; margin: 2px 0px 2px 10px"
							placeholder="请选择"
							size="mini"
							class="choose"
							@change="chooseTypeHandle"
						>
							<el-option
								v-for="item in dataList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
						<el-input
							v-if="chooseType == 1"
							v-model="pageParams.dataCode"
							size="mini"
              class="choose-value"
							placeholder="请输入关键字"
							style="
								width: 160px;
								margin-right: 10px;
								margin-top: 0px;
							"
						></el-input>
						<el-input
							v-if="chooseType == 2"
							v-model="pageParams.residentName"
							size="mini"
              class="choose-value"
							placeholder="请输入关键字"
							style="
								width: 160px;
								margin-right: 10px;
								margin-top: 0px;
							"
						></el-input>
						<el-input
							v-if="chooseType == 3"
							v-model="pageParams.mobile"
							size="mini"
              class="choose-value"
							placeholder="请输入关键字"
							style="
								width: 160px;
								margin-right: 10px;
								margin-top: 0px;
							"
						></el-input>
						<el-input
							v-if="chooseType == 4"
							v-model="pageParams.address"
							size="mini"
              class="choose-value"
							placeholder="请输入关键字"
							style="
								width: 160px;
								margin-right: 10px;
								margin-top: 0px;
							"
						></el-input>
						<el-input
							v-if="chooseType == 5"
							v-model="pageParams.content"
							size="mini"
              class="choose-value"
							placeholder="请输入关键字"
							style="
								width: 160px;
								margin-right: 10px;
								margin-top: 0px;
							"
						></el-input>
						<el-form-item label="" class="searchItem">
							<el-button
								type="primary"
								@click="search"
								size="mini"
								>查询</el-button
							>
							<el-button
								type="warning"
								@click="restSearch"
								size="mini"
								>重置</el-button
							>
							<el-button type="primary" @click="derive" v-if="radio == 1" size="mini">导出</el-button>
						</el-form-item>
					</el-form>
					<el-button
						type="primary"
						@click="hfMore"
						size="mini"
						v-if="$anthButtons.getButtonAuth('bjjlajhf') && radio == 1"
						>回访</el-button
					><el-button
						type="primary"
						@click="batchRedeploy"
						size="mini"
						v-if="$anthButtons.getButtonAuth('batchjhf') && radio == 2"
						>批量转派</el-button
					>
					<el-table
						ref="tableData"
						:data="tableData"
						style="margin-top: 10px"
						border
						:header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
						height="calc(100vh - 400px)"
						:stripe="true"
						@sort-change="sort_change"
						@selection-change="handleSelectionChange"
					>
						<el-table-column
							type="selection"
							width="50"
							align="center"
							fixed="left"
							:selectable="selected"
						>
						</el-table-column>
						<el-table-column
							type="index"
							:index="indexMethod"
							label="序号"
							align="center"
							width="50"
						>
						</el-table-column>
						<el-table-column
							prop="dataCode"
							label="工单编号"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>

						<el-table-column
							prop="residentName"
							label="户主姓名"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="mobile"
							label="户主电话"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>

						<el-table-column
							prop="userTypeName"
							label="用户分类"
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="addrName"
							label="片区"
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="address"
							label="地址"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="content"
							label="隐患内容"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="woodenState"
							label="隐患说明"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="priorLevelName"
							label="优先级"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="deptName"
							label="受理部门"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="userName"
							label="受理人"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="createTime"
							label="创建时间"
							sortable="custom"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="status"
							label="工单状态"
							show-overflow-tooltip
							width="180"
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											statusList2,
											row.status,
											"id",
											"name"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							prop="repairman"
							label="维修员"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="checkTime"
							label="维修时间"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="inspectionTime"
							label="用时"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>

						<el-table-column
							prop="recordRemark"
							label="备注"
							show-overflow-tooltip
							width="180"
						>
						</el-table-column>
						<el-table-column
							prop="operateName"
							label="操作人"
							show-overflow-tooltip
							width="180"
						>
              <template slot-scope="{ row }">
                <span v-if="row.status==5||row.status==6">{{row.operateName}}</span>
              </template>
						</el-table-column>
						<el-table-column
							prop="isOvertime"
							label="超时"
							show-overflow-tooltip
						>
							<template slot-scope="{ row }">
								<p>
									{{
										getDictLabel(
											isOvertimeList,
											row.isOvertime,
											"id",
											"name"
										)
									}}
								</p>
							</template>
						</el-table-column>
						<el-table-column
							label="操作"
							align="left"
							width="180"
							fixed="right"
						>
							<template slot-scope="{ row }">
								<div class="operateTool">
									<el-button
										type="primary"
										size="mini"
										style="margin-right: 10px"
										@click="
											goPath(
												'/home/kfOrderDeil',
												row.dataId,
												2
											)
										"
										>查看</el-button
									>
									<el-button
										type="primary"
										size="mini"
										style="margin-right: 10px"
										@click="maintain(row)"
										v-if="$anthButtons.getButtonAuth('maintainjhf') && radio == 2"
										>维修</el-button
									>
									<!--  -->
									<el-dropdown
										@command="
											(command) => exchange(command, row)
										"
										trigger="click"
										v-if="radio == 1"
									>
										<el-button
											type="text"
											size="mini"
											style="
												border: 1px solid #1082ff;
												color: #1082ff;
												padding: 0 11px;
												height: 30px;
											"
											>更多
											<i
												class="
													el-icon-caret-bottom
													el-icon--right
												"
											></i>
										</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item
												command="0"
												v-if="
													row.status != 4 &&
													row.status != 5 &&
													row.status != 7 &&
													$anthButtons.getButtonAuth(
														'bjjlajpg'
													)
												"
												>派工</el-dropdown-item
											>
											<el-dropdown-item
												command="1"
												v-if="
													row.status != 4 &&
													row.status != 5 &&
													row.status != 7 &&
													$anthButtons.getButtonAuth(
														'bjjlajzp'
													)
												"
												>转派</el-dropdown-item
											>
											<el-dropdown-item
												v-if="row.pcTop != 1"
												command="2"
												>置顶</el-dropdown-item
											>
											<el-dropdown-item
												command="7"
												v-if="row.pcTop == 1"
												>取消置顶</el-dropdown-item
											>
											<el-dropdown-item
												command="3"
												v-if="
													row.status == 4 &&
													$anthButtons.getButtonAuth(
														'bjjlajhf'
													)
												"
												>回访</el-dropdown-item
											>
											<el-dropdown-item
												command="4"
												v-if="
													row.status == 3 &&
													$anthButtons.getButtonAuth(
														'bjjlajgb'
													)
												"
												>关闭</el-dropdown-item
											>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<div class="total">
						<el-pagination
							background
							layout="total, prev, pager, next,sizes, jumper"
							:total="total"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="pageParams.current"
							:page-sizes="[10, 30, 50, 100]"
							:page-size="pageParams.size"
						>
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<HandleDialog
			ref="handleDialog"
			:pgdialogVisible="pgdialogVisible"
			:zpdialogVisible="zpdialogVisible"
			:hfdialogVisible="hfdialogVisible"
			:gbdialogVisible="gbdialogVisible"
			:maindialogVisible="maindialogVisible"
			@close="closeDialog"
			:dataId="dataId"
		></HandleDialog>
	</div>
</template>

<script>
	import pageMixins from "@/utils/pageMixins";
	import {
		securityOrder,
		setPcTop,
		selectAllUsersByDeptId,
		exportDataList2
	} from "@/RequestPort/mainOrder";
	import { getTimeoutList } from "@/RequestPort/maintenance";
	import { selectSysDeptTreeList } from "@/RequestPort/user/user";
	import { selectDictListByPid } from "@/RequestPort/device/deviceList";
	import HandleDialog from "./components/handleDialog";
	import { getDictLabel } from "@/utils/common";
	import {exportMethod} from "../../common/js/exportExcel";
	export default {
		mixins: [pageMixins],
		components: {
			HandleDialog,
		},
		data() {
			return {
				getDictLabel,
				TreeProps: {
					label: "name",
					value: "id",
					children: "children",
					emitPath: false,
					checkStrictly: true,
				},
				pgdialogVisible: false,
				zpdialogVisible: false,
				hfdialogVisible: false,
				gbdialogVisible: false,
				maindialogVisible:false,
				dataId: "",
				time: [],
				chooseType: 1,
				userTypeList: [],
				repairTypeList: [], //报修类型
				priorityList: [], //优先级
				// 受理部门
				sectionList: [],
				// 维修人
				maintainUserList: [],
				// 工单状态
				statusList: [
					{ name: "待派工", id: 1 },
					{ name: "执行中", id: "2,6" },
					{ name: "未维修", id: 3 },
					{ name: "已维修", id: 4 },
					{ name: "已回访", id: 5 },
					{ name: "已关闭", id: 7 },
				],
				statusList2: [
					{ name: "待派工", id: 1 },
					{ name: "执行中", id: 2 },
					{ name: "未维修", id: 3 },
					{ name: "已维修", id: 4 },
					{ name: "已回访", id: 5 },
					{ name: "执行中", id: 6 },
					{ name: "已关闭", id: 7 },
				],
				//异常项
				exceptionList: [
					{ name: "无", id: 1 },
					{ name: "有", id: 2 },
				],
				// 是否超时
				isOvertimeList: [
					{ name: "未超时", id: 1 },
					{ name: "已超时", id: 2 },
				],
				peopleList: [],
				dataList: [
					{ label: "工单编号", value: 1 },
					{ label: "户主姓名", value: 2 },
					{ label: "户主电话", value: 3 },
					{ label: "地址", value: 4 },
					{ label: "隐患内容", value: 5 },
				],
				timeList: [
					{ label: "创建时间", value: 1 },
					{ label: "维修时间", value: 2 }
				],
				timeStart:1,
				pageParams: {
					current: 1,
					size: 100,
					status:[]
				}, //  分页查询参数
				total: null,
				tableData: [], //  表格数据
				selectAll: [], //  选中表格数据
				title: "",
				radio:'1'
			};
		},
		mounted() {
			this.loadList(this.pageParams);
			this.getInfo();
		},
		methods: {
			radioChange(val){
				this.pageParams.status = []
				// 未完成
				if(val == 2) {
					this.statusList= [
						{ name: "待派工", id: 1 },
						{ name: "执行中", id: "2,6" },
						{ name: "未维修", id: 3 }
					]
					this.pageParams.unComplete = 1
					this.loadList(this.pageParams,1)
				} else {
					this.statusList = [
						{ name: "待派工", id: 1 },
						{ name: "执行中", id: "2,6" },
						{ name: "未维修", id: 3 },
						{ name: "已维修", id: 4 },
						{ name: "已回访", id: 5 },
						{ name: "已关闭", id: 7 },
					]
					this.pageParams.unComplete = null
					this.loadList(this.pageParams,1)
				}
			},
			batchRedeploy(){
				if (this.selectAll.length == 0) {
					this.$message.error("请先勾选数据");
					return;
				} else {
					let arr = [];
					this.selectAll.forEach((item) => {
						arr.push(item.dataId);
					});
					this.dataId = arr.toString();
					// this.hfdialogVisible = true;
					// 部门下拉
					selectSysDeptTreeList({ deptListShow: "n" }).then((e) => {
						let tree = e.data;
						if (tree[0]) {
							tree[0].disabled = true;
						}
						// 特殊处理
						if (tree[0].children) {
							tree[0].children.forEach((el) => {
								if (el.id == 8) {
									el.disabled = true;
								}
							});
						}
						this.$refs.handleDialog.getInfo(1, this.dataId, tree);
						//转派
						this.zpdialogVisible = true;
					});
				}
			},
			maintain(row){
				this.dataId = row.dataId
				this.$refs.handleDialog.getInfo()
				this.$refs.handleDialog.getsecurityOrderDetails(row.dataId)
				this.$refs.handleDialog.selectDepartment(row.deptId,true);
				//维修
				this.maindialogVisible = true;
				this.$nextTick(() => {
					this.$refs.handleDialog.$refs.mainform.clearValidate()
				})
			},
			sort_change(val) {
				let sort;
				if (val.order == "descending") sort = "desc";
				if (val.order == "ascending") sort = "asc";
				this.pageParams.sort = sort;
				if (!val.order) {
					delete this.pageParams.sort;
				}
				this.loadList(this.pageParams);
			},
			timeChange(val) {
				delete this.pageParams.startTime 
					delete this.pageParams.endTime 
					delete this.pageParams.startTime1
					delete this.pageParams.endTime1 
				if (val && val.length) {
					if(this.timeStart == 1) {
						this.pageParams.startTime = val[0] + " 00:00:00";
						this.pageParams.endTime = val[1] + " 23:59:59";
					} else {
						this.pageParams.startTime1 = val[0] + " 00:00:00";
						this.pageParams.endTime1 = val[1] + " 23:59:59";
					}
				} else {
					if(this.timeStart == 1) {
						this.pageParams.startTime = null;
						this.pageParams.endTime = null;
					} else {
						this.pageParams.startTime1 = null;
						this.pageParams.endTime1 = null;
					}
				}
			},
			derive(){
				let data = {...this.pageParams}
				data.status = data.status.join(",");
				exportDataList2(data).then(res => {
					exportMethod(res, '安检工单')
				})
			},
			chooseTypeTime(val){
				this.timeStart = val
				this.time = []
				this.timeChange(this.time)
			},
			getInfo() {
				selectDictListByPid({ pid: 732 }).then((res) => {
					this.userTypeList = res.data;
				});
				// 优先级
				getTimeoutList().then((res) => {
					this.priorityList = res.data;
				});
				// 部门下拉
				selectSysDeptTreeList({ deptListShow: "n" }).then((e) => {
					let tree = e.data;
					// if (tree[0]) {
					// 	tree[0].disabled = true;
					// }
					// // 特殊处理
					// if (tree[0].children) {
					// 	tree[0].children.forEach((el) => {
					// 		if (el.id == 8) {
					// 			el.disabled = true;
					// 		}
					// 	});
					// }
					this.sectionList = tree;
				});
			},
			/**
			 * 选择部门
			 */
			selectDepartment(val) {
				console.log(val);
				// 通过部门id查此部门及其下所有部门用户（人员下拉）
				selectAllUsersByDeptId({ deptId: val, code: "aj006" }).then(
					(res) => {
						this.maintainUserList = res.data;
					}
				);
			},
			selected(row) {
				if (row.status == 4 || this.radio == 2) {
					return true; //可勾选
				} else {
					return false; //不可勾选
				}
			},
			handleSelectionChange(val) {
				this.selectAll = val;
			},
			hfMore() {
				if (this.selectAll.length == 0) {
					this.$message.error("请先勾选数据");
					return;
				} else {
					let arr = [];
					this.selectAll.forEach((item) => {
						arr.push(item.dataId);
					});
					this.dataId = arr.toString();
					this.hfdialogVisible = true;
				}
			},
			chooseTypeHandle(val) {
				if (val == 1) {
					this.$set(this.pageParams, "dataCode", "");
					this.$set(this.pageParams, "residentName", "");
					this.$set(this.pageParams, "mobile", "");
					this.$set(this.pageParams, "address", "");
					this.$set(this.pageParams, "content", "");
				} else if (val == 2) {
					this.$set(this.pageParams, "dataCode", "");
					this.$set(this.pageParams, "residentName", "");
					this.$set(this.pageParams, "mobile", "");
					this.$set(this.pageParams, "address", "");
					this.$set(this.pageParams, "content", "");
				} else if (val == 3) {
					this.$set(this.pageParams, "dataCode", "");
					this.$set(this.pageParams, "residentName", "");
					this.$set(this.pageParams, "mobile", "");
					this.$set(this.pageParams, "address", "");
					this.$set(this.pageParams, "content", "");
				} else if (val == 4) {
					this.$set(this.pageParams, "dataCode", "");
					this.$set(this.pageParams, "residentName", "");
					this.$set(this.pageParams, "mobile", "");
					this.$set(this.pageParams, "address", "");
					this.$set(this.pageParams, "content", "");
				} else if (val == 5) {
					this.$set(this.pageParams, "dataCode", "");
					this.$set(this.pageParams, "residentName", "");
					this.$set(this.pageParams, "mobile", "");
					this.$set(this.pageParams, "address", "");
					this.$set(this.pageParams, "content", "");
				}
			},
			closeDialog(command) {
				if (command == 0) {
					//派工
					this.pgdialogVisible = false;
				}
				if (command == 1) {
					//zhuanpai
					this.zpdialogVisible = false;
				}
				if (command == 3) {
					//zhuanpai
					this.hfdialogVisible = false;
				}
				if (command == 4) {
					//zhuanpai
					this.gbdialogVisible = false;
				}
				if (command == 5) {
					//维修
					this.maindialogVisible = false;
				}
				this.loadList(this.pageParams);
			},
			exchange(command, row) {
				this.dataId = row.dataId;
				if (command == 0) {
					selectAllUsersByDeptId({
						deptId: row.deptId,
						code: "aj006",
					}).then((res) => {
						//派工
						this.$refs.handleDialog.getInfo(
							command,
							row.deptId,
							res.data
						);
						this.pgdialogVisible = true;
					});
				}
				if (command == 1) {
					// 部门下拉
					selectSysDeptTreeList({ deptListShow: "n" }).then((e) => {
						let tree = e.data;
						if (tree[0]) {
							tree[0].disabled = true;
						}
						// 特殊处理
						if (tree[0].children) {
							tree[0].children.forEach((el) => {
								if (el.id == 8) {
									el.disabled = true;
								}
							});
						}
						this.$refs.handleDialog.getInfo(command, row.deptId, tree);
						//转派
						this.zpdialogVisible = true;
					});
				}
				if (command == 3) {
					//回访
					this.hfdialogVisible = true;
				}
				if (command == 2 || command == 7) {
					//置顶/取消置顶
					this.getTop(row, command == 2 ? 1 : 2);
				}
				if (command == 4) {
					//关闭
					this.gbdialogVisible = true;
				}
			},
			getTop(row, pcTop) {
				setPcTop({ dataId: row.dataId, pcTop }).then((res) => {
					this.$message.success(res.data);
					this.loadList(this.pageParams);
				});
			},
			search() {
				this.pageParams.current = 1;
				this.loadList(this.pageParams);
			},
			restSearch() {
				this.time = [];
				this.pageParams = {
					userType: "",
					userId: "",
					status:[],
					taskCode: "",
					finishType: "",
					current: 1,
					size: 100,
				};
				this.pageParams.unComplete = this.radio == 1 ? null : 1
				this.loadList(this.pageParams);
			},
			loadList(obj,type) {
				console.log(1111111111)
				if(type){
				this.pageParams.current = 1;
				}
				obj = JSON.parse(JSON.stringify(obj));
				if (obj.status && obj.status.length > 0) {obj.status = obj.status.join(",");}
				securityOrder(obj).then((res) => {
					this.tableData = res.data.records;
					this.total = res.data.total;
				});
			},
			handleCurrentChange(current) {
				this.pageParams.current = current;
				this.loadList(this.pageParams);
			},
			handleSizeChange(val) {
				this.pageParams.size = val;
				this.handleCurrentChange(1);
			},
			//跳转
			goPath(path, id, type) {
				if(type == 2) {
					let chakan = this.$router.resolve({
						path: path,      //跳转目标窗口的地址
						query:  { dataId: id, type }
					})
					window.open(chakan.href);
				} else {
					this.$router.push({
						path: path,
						query: { dataId: id, type },
					});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.SearchBar .el-form {
		width: 1620px;
	}
	.searchBox {
		.searchBoxForm {
			display: flex;
			align-items: center;
		}
	}
	.choose {
		::v-deep .el-input__inner {
			background-color: #f9f9f9;
			border-radius: 4px 0px 0 4px;
			border-right: 0;
		}
	}
	.choose-value {
		::v-deep .el-input__inner {
			border-radius: 0px 4px 4px 0;
		}
	}
	::v-deep .el-form-item--mini.el-form-item,
	.el-form-item--small.el-form-item {
		margin-bottom: 10px;
		margin-right: 30px;
	}
	.tableTool {
		height: 25px;
	}
	.label {
		color: #909399;
	}
	.content-item {
		color: #444444;
	}
	.SearchBar {
		border-radius: 5px;
		margin: 10px;
		padding-top: 10px;
		padding-left: 20px;
		background-color: #ffffff;
	}
	.all {
		background-color: #f3f4f8;
		height: auto;
	}
	.content {
		background: #f3f4f8;
		width: 100%;
		border-radius: 6px;
		overflow: auto;
		height: calc(100vh - 120px);
	}
	.search {
		width: 190px;
	}
	.total {
		margin-left: 15px;
		margin-top: 10px;
	}
	.table {
		overflow: auto;
		height: 520px;
	}
	::v-deep.special .el-input__inner{
		height: 28.5px;
	}
</style>